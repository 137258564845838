._10ekBHTP6NHVXR-_3Gxz8L {
  max-width: 1300px;
  width: 100%;
  margin: 0 auto;
}

._3MqFonOQlvkWLkdwtw9i_r {
  width: 100%;
  margin: 0 auto;
  padding: 0 var(--side-margin-desktop) 25px var(--side-margin-desktop);
}

._1A5I37_TJkzQN_yEmYjFbG {
  display: block;
  width: 175px;
  margin: 0 auto;
  padding: 20px 0 10px 0;
}

._1A5I37_TJkzQN_yEmYjFbG svg {
  width: 100%;
}

._10rJeBi4WRirFDycF8iBG {
  text-align: center;
  font-size: 2.5rem;
}

@media (min-width: 768px) {
  ._10rJeBi4WRirFDycF8iBG {
    font-size: 3rem;
  }
}

._15GRno4jugkcn2SQCS1Q_Y {
  padding: 10px 0;
  text-align: center;
  font-size: 1.15rem;
  max-width: 350px;
  margin: 0 auto;
}

._3ojPy_uoDJ357Bs96aH1Ah {
  width: 250px;
  height: 2.5px;
  margin: 10px auto;
  background-color: var(--yellow-primary);
}

.E5NFdG-c1Gwn-SwiybAE4 {
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
}

._2px6lDWbD7HqNvfAWMkBoE {
  background-color: white;
  border-radius: var(--big-border-radius);
  padding: 20px;
  margin: 15px 0;
}

@media (min-width: 768px) {
  ._2px6lDWbD7HqNvfAWMkBoE {
    padding: 25px;
  }
}

._3NkNe600X2QzT5F8NKcBP_ {
  box-shadow: 0 1px 13px 0 rgba(0, 0, 0, 0.25);
}

._1c8DRqAFCXCK4x_-I5y5Ry {
  width: 100%;
  max-width: 750px;
}

._2Oy4FwIJ8k9ey7dTvEgMPZ {
  width: 100%;
  max-width: 450px;
}

@media (min-width: 768px) {
  ._1c8DRqAFCXCK4x_-I5y5Ry {
    margin-inline-end: 30px;
  }

  ._2Oy4FwIJ8k9ey7dTvEgMPZ {
    margin-inline-start: 30px;
  }
}

._3fCSnTywOLvr0fz4GhZ38G {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-top: 30px;
}

.WPc0p_jswWEaRs5J0Tl7J {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

._1NnJbIJWKmIVuKX0j2ZZRT {
  width: 100%;
}

@media (min-width: 768px) {
  ._1NnJbIJWKmIVuKX0j2ZZRT {
    max-width: 340px;
    margin-inline-start: 20px;
  }
}

._3TUwsrjzaX4sMoOri7_1hQ {
  width: 100%;
  margin-bottom: 30px;
}

._3TUwsrjzaX4sMoOri7_1hQ svg {
  width: 100%;
}

._1ZArGOHHDOKEwmm3FTMqhp {
  align-self: center;
  width: 100%;
}

.XFypcY2ExcJSrOxwcPo37 {
  display: none;
  width: 100%;
}

.PR6UMB9fnGUz7pqL5Gu-8 {
  width: 100%;
}

@media (min-width: 768px) {
  .XFypcY2ExcJSrOxwcPo37 {
    display: block;
    width: 100%;
  }

  .PR6UMB9fnGUz7pqL5Gu-8 {
    display: none;
  }

  ._3TUwsrjzaX4sMoOri7_1hQ {
    margin-bottom: 0;
  }

  ._1ZArGOHHDOKEwmm3FTMqhp {
    width: 340px;
  }
}

._12xPb250xnjibomo7SAM7j {
  font-size: 1.8rem;
  margin: 0 0 15px 0;
}

._2P1aygMX31kTKVkTbDhaQx {
  font-size: 0.95rem;
  color: var(--gray-2);
  padding-bottom: 20px;
}

._2awyUMgQQjD50DXf85DwZI {
  padding: 10px 0;
  font-size: 1.3rem;
}

._349xnYYULSHTZ35CZ2Ceiw {
  margin: 0;
  list-style-type: none;
  padding: 0 0 10px 0;
}

._3mQ7SfO-nGCGxjJ3JOTzdz {
  margin-bottom: 5px;
}

._3mQ7SfO-nGCGxjJ3JOTzdz::before {
  content: "- ";
}

.My3tYDU6PkLVa90_yPJdD {
  font-size: 0.95rem;
  color: var(--gray-2);
}
