._2_6sB4NGJTb43MjvBqq56e {
  max-width: 1300px;
  width: 100%;
  margin: 0 auto;
}

._2bdE-uOCbDiAuKHYTPTJfa {
  width: 100%;
  margin: 0 auto;
  padding: 0 var(--side-margin-desktop) 25px var(--side-margin-desktop);
}

._2ygqcO63Xnolb_Zy5lUnXA {
  display: block;
  width: 175px;
  margin: 0 auto;
  padding: 20px 0 10px 0;
}

._2ygqcO63Xnolb_Zy5lUnXA svg {
  width: 100%;
}

._1gNihjkqqe4RaI5wHtrvHd {
  text-align: center;
  font-size: 2.5rem;
}

._3Vxts11SkNkS5Fblzc1Mzs {
  padding: 10px 0;
  text-align: center;
  font-size: 1.15rem;
  max-width: 350px;
  margin: 0 auto;
}

._2cgsDvhBJ0-7uv3qWMLWuK {
  width: 100%;
  display: flex;
  max-width: 320px;
  justify-content: center;
  margin: 40px auto;
}

._2cgsDvhBJ0-7uv3qWMLWuK svg {
  width: 80%;
}

._1B2nry2ZQ-1zaU4TX_5jsd {
  display: flex;
  justify-content: center;
  align-items: center;
}
