._2MVFLX6-M4rZkNsOb6N0Px {
  display: block;
  box-shadow: none;
  background-color: white;
  outline: none;
  height: 50px;
  width: 100%;
  border-radius: 8px;
  padding: 0 10px;
  border: var(--gray-5) solid 2px;
  font-size: 14px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.DvsFwO4kTfS0TTPCdr7eM {
  /* -moz-appearance: none;
  appearance: none;
  background-image: url('/icons/others/down.svg');
  background-repeat: no-repeat;
  background-position: end center;
  padding-right: 20px; */
  background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHBvbHlnb24gY2xhc3M9ImNscy0yIiBwb2ludHM9IjEuNDEgNC42NyAyLjQ4IDMuMTggMy41NCA0LjY3IDEuNDEgNC42NyIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIzLjU0IDUuMzMgMi40OCA2LjgyIDEuNDEgNS4zMyAzLjU0IDUuMzMiLz48L3N2Zz4=) no-repeat 98% 50%;
  background-color: white;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.DvsFwO4kTfS0TTPCdr7eM:lang(ar) {
  background-position-x: 2%;
}

._2MVFLX6-M4rZkNsOb6N0Px:hover {
  border: var(--gray-4) solid 2px;
}

._2MVFLX6-M4rZkNsOb6N0Px:focus {
  border: var(--blue-primary) solid 2px;
}

._3IhrjizKVNxaLpWC5mLTGo {
  margin: 0 0;
  margin-inline-end: 5px;
  border-radius: 8px;
}
