@media (max-width: 768px) {
  ._2DWSdbKC-_NNPeLFAaKmFL {
    display: none !important;
  }
}

@media (min-width: 768px) {
  ._17LtjYCWX1aq4DMELwWRjo {
    display: none !important;
  }
}
