._26OMUent-awywAbj-71EpK {
  width: 100%;
}

._2kLaPZz53NITfB5NcoPoQx {
  background-color: var(--gray-1);
  /* background-image: url('/stock-photos/cairo-1.jpg'); */
  /* background-size: contain; */
  border-radius: 30px;
  padding: 50px var(--side-margin-desktop);
}

._2d_P7gwWMFBIrEfYkngXlv {
  margin: 0 0 20px 0;
  color: white;
  width: 100%;
  font-size: 2.75rem;
  font-weight: bold;
  text-align: center;
  /* font-family: "Noto Serif JP", Georgia, 'Times New Roman', Times, serif; */
}

@media (min-width: 768px) {
  ._2d_P7gwWMFBIrEfYkngXlv {
    font-size: 3rem;
  }
}

._3rH0Yhnj0RLHilGgsNoHc2 {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  margin-top: 30px;
  margin-bottom: 15px;
  width: 150px;
  height: 28px;
  border: solid rgba(0, 0, 0, 0.6) 0;
  border-radius: var(--big-border-radius);
  background-color: rgba(150, 150, 150);
  cursor: pointer;
  outline: none;
}

._3rH0Yhnj0RLHilGgsNoHc2:hover {
  background-color: rgba(160, 160, 160);
}

._20AbSi0nGAed3X6WbBRQ-6 {
  font-size: 1rem;
  text-align: center;
  width: 50%;
  z-index: 20;
  color: var(--gray-1);
}

._1hZAKLecwBzZdPpvUipX-R {
  position: absolute;
  z-index: 10;
  width: 75px;
  height: 100%;
  border-radius: var(--big-border-radius);
  background-color: white;
}

.JMKnC2i0U5m0GJVMx5X22 {
  /* inset-inline-end: 0;  very poor support */
  right: 0;
}

._1YNOpPWUfUCSMhQwn56ZJv {
  left: 0;
}

._2B4wmVWH1sPd9qlPk8r-l_ {
  padding: 30px 0 0 0;
  font-size: 0.8rem;
  text-align: center;
  color: var(--gray-5);
}

._1e98GQuCedRwEjS3RNcSV- {
  font-size: 0.8rem;
  color: var(--gray-5);
}

._1e98GQuCedRwEjS3RNcSV-:visited {
  font-size: 0.8rem;
  color: var(--gray-5);
}

._3QMGZRk0SkCl82NcfGBUbe {
  width: 100%;
}
