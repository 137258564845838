._1qpDw3L_sfCtU4fHYod5FQ {
  height: 50px;
  padding: 0 10px;
  display: flex;
  max-width: 600px;
  margin: 0 auto;
  background-color: white;
  border-radius: var(--big-border-radius);
}

._1qpDw3L_sfCtU4fHYod5FQ:hover button svg g g path {
  stroke: rgba(0, 0, 0, 0.6);
}

._1qpDw3L_sfCtU4fHYod5FQ:hover button svg g g circle {
  stroke: rgba(0, 0, 0, 0.6);
}

._1qpDw3L_sfCtU4fHYod5FQ:focus-within button svg g g path {
  stroke: var(--blue-primary);
}

._1qpDw3L_sfCtU4fHYod5FQ:focus-within button svg g g circle {
  stroke: var(--blue-primary);
}

._3hdTeaHMbccjE4A_R3_2Qm {
  width: 100%;
  height: 100%;
  border: none;
  background-image: none;
  outline: none;
  background-color: transparent;
  box-shadow: none;
  margin-inline-start: 10px;
  font-size: 1.1rem;
}

.v89yMa3W0bf8qAiMg3oze,
input[type="submit"],
input[type="reset"] {
  background: none;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  width: 50px;
  border-radius: var(--big-border-radius);
  color: var(--gray-1);
}

.v89yMa3W0bf8qAiMg3oze svg g g path {
  stroke: var(--gray-3);
  stroke-width: 2.5pt;
}

.v89yMa3W0bf8qAiMg3oze svg g g circle {
  stroke: var(--gray-3);
  stroke-width: 2.5pt;
}

.v89yMa3W0bf8qAiMg3oze:hover svg g g path {
  stroke: var(--blue-primary);
}

.v89yMa3W0bf8qAiMg3oze:hover svg g g circle {
  stroke: var(--blue-primary);
}

.v89yMa3W0bf8qAiMg3oze:focus svg g g path {
  stroke: var(--blue-primary);
}

.v89yMa3W0bf8qAiMg3oze:focus svg g g circle {
  stroke: var(--blue-primary);
}

.v89yMa3W0bf8qAiMg3oze svg {
  width: 18px;
  overflow: visible;
}
