._1x0iwsQvioDJny-KbzhKRg {
  /* Don't forget to change the .pushBody class when you change this */
  --bottom-nav-height: 55px;
  height: var(--bottom-nav-height);

  display: flex;
  justify-content: space-between;
  align-items: center;

  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  width: 100%;

  background-color: var(--gray-9);
  border-top: var(--gray-5) solid 1px;
  padding: 5px var(--side-margin-mobile);
}

._2EU6ztQvVzkY2ioZE_gjYy {
  width: 100%;
  height: 60px;
}

@media (min-width: 768px) {
  ._1x0iwsQvioDJny-KbzhKRg {
    display: none;
  }

  ._2EU6ztQvVzkY2ioZE_gjYy {
    display: none;
  }
}

._1T24i5OaEgpI_g3BOyGrwA {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
}

._1T24i5OaEgpI_g3BOyGrwA svg {
  height: 100%;
  padding: 10px 0;
}
