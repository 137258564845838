.r-d9jYqBSQf35I-ELb4Ey {
  max-width: 1300px;
  width: 100%;
  margin: 0 auto;
}

._3E5DONR0mO4l-unS9JROSt {
  width: 100%;
  margin: 0 auto;
  padding: 0 var(--side-margin-desktop) 25px var(--side-margin-desktop);
}

._3JlYBUzu4ODR9bs_xutA4j {
  display: block;
  width: 200px;  /* 175px */
  margin: 0 auto;
  padding: 30px 0;
}

._3JlYBUzu4ODR9bs_xutA4j svg {
  width: 100%;
  overflow: visible;
}

._3v9lHOdMJEXB6edQnhwXym {
  padding-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

._1jUFeVJ56RvF7aFol1sewx {
  width: 100%;
}

._1AlNPqQdshUNcGlvgx67C1 {
  padding-top: 30px;
}

@media (min-width: 768px) {
  ._1jUFeVJ56RvF7aFol1sewx {
    max-width: 36vw;
    padding: 25px;
    padding-top: 0;
  }

  ._1AlNPqQdshUNcGlvgx67C1 {
    max-width: 36vw;
    padding-top: 0;
  }
}

._2X_fedhALhnPmua_iRKpM0 {
  font-size: 2.5rem;
  padding-bottom: 10px;
}

.n5lDdIi2GFdHg6qQwtuxD {
  font-size: 1.1rem;
  color: var(--gray-2);
  padding-bottom: 10px;
}

._2NduoeHgIQ-j91nySGYVtG {
  font-size: 1.8rem;
  padding-bottom: 10px;
}

._1WohoW4oiwThTXBr7FKWwb {
  padding-top: 30px;
}

._2Eyn5tkPhTQJrOVW6nyth- {
  padding-bottom: 25px;
}

.aNPrr9lbEw6uveI1wSUS1 {
  font-size: 1.4rem;
  padding-bottom: 5px;
}

._1MIn2MeM2ICs0FRXzQg8zE {
  color: var(--gray-2);
}

._3RV0K1BSul7Fdl1cWbWfwx {
  background-color: white;
  box-shadow: 0 1px 13px 0 rgba(0, 0, 0, 0.25);
  padding: 20px;
  border-radius: var(--big-border-radius);
}

@media (min-width: 768px) {
  ._3RV0K1BSul7Fdl1cWbWfwx {
    padding: 25px;
  }
}

._3-jePeuH_KZvkIW4rV2vR3 {
  padding-top: 10px;
}

@media (min-width: 768px) {
  ._3-jePeuH_KZvkIW4rV2vR3 {
    padding-top: 0;
  }
}

.VyYmmPE-sYftm3zQY0bvM {
  padding: 20px 0 5px 0;
}

._1jS7Y8kffNaDgO-Rpcd2vw {
  padding: 10px 0;
}

.zot8nn-ci0pxwEcYP2DiG {
  display: block;
  padding-bottom: 5px;
}

.I1Mv-TX-AFwlYryt7sG2P {
  display: flex;
  justify-content: flex-end;
  padding: 20px 0 5px 0;
}

._3AnZpxYoWq5VtaC6f5Vvvq,
._3AnZpxYoWq5VtaC6f5Vvvq div {
  display: flex;
  justify-content: center;
  align-items: center;
}

._3AnZpxYoWq5VtaC6f5Vvvq p {
  color: white;
  margin-inline-end: 15px;
}
