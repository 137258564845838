._3MzJFR4VeSWRLMkiBDo4t8 {
  display: block;
}

._3MzJFR4VeSWRLMkiBDo4t8:link {
  text-decoration: inherit;
  color: inherit;
}

._3MzJFR4VeSWRLMkiBDo4t8:visited {
  text-decoration: inherit;
  color: inherit;
}

._3MzJFR4VeSWRLMkiBDo4t8:hover {
  color: var(--blue-primary);
}

.LP3hK5nega8uCiYIVHPwQ {
  display: block;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  border-radius: var(--normal-border-radius);
}

.LP3hK5nega8uCiYIVHPwQ:disabled {
  background-color: var(--gray-3);
  color: var(--gray-10);
}

.LP3hK5nega8uCiYIVHPwQ:visited,
.LP3hK5nega8uCiYIVHPwQ:link {
  text-decoration: none;
}

._1XD-PIDhPwf_C-UHbojZTK {
  background-color: var(--blue-primary);
  color: white;
}

._1XD-PIDhPwf_C-UHbojZTK:visited,
._1XD-PIDhPwf_C-UHbojZTK:link {
  color: white;
}

._1XD-PIDhPwf_C-UHbojZTK:hover,
._1XD-PIDhPwf_C-UHbojZTK:active {
  background-color: var(--blue-dark);
  transition: border-color 125ms linear;
  transition: color 125ms linear;
}

._3DQ6civxQz0m_1mgn9khF0 {
  color: var(--gray-1);
  border: solid 1px var(--gray-1);
}

._3DQ6civxQz0m_1mgn9khF0:visited,
._3DQ6civxQz0m_1mgn9khF0:link {
  color: var(--gray-1);
}

._3DQ6civxQz0m_1mgn9khF0:hover,
._3DQ6civxQz0m_1mgn9khF0:active {
  border-color: var(--blue-primary);
  color: var(--blue-primary);
  transition: border-color 125ms linear;
  transition: color 125ms linear;
}

._3kAWHETFfL_I5EiERJvVcj {
  padding: 10px 10px;
  min-width: 180px;
  min-height: 40px;
}

._391HurDSagMyGDj88HlkEH {
  padding: 10px 10px;
  min-width: 150px;
  min-height: 30px;
}
