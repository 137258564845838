._3tDgYQG0Y3_RbV37fwxHDE {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  outline: inherit;
}

._1XaIXXv4EL7q1lkXJn_zod {
  background-color: var(--blue-primary);
  color: var(--gray-10);
  height: 60px;
  width: 220px;
  border-radius: 8px;
  font-weight: bold;
  font-size: 16px;
}

._1XaIXXv4EL7q1lkXJn_zod:focus,
._1XaIXXv4EL7q1lkXJn_zod:hover {
  color: white;
  background-color: var(--blue-dark);
}

._1XaIXXv4EL7q1lkXJn_zod:disabled {
  background-color: var(--gray-3);
  cursor: auto;
}

._1ulLbF8JPsZ2lATMDNHNxO {
  color: var(--blue-primary);
  height: 60px;
  width: 220px;
  border-radius: 8px;
  font-weight: bold;
  font-size: 16px;
  border: 2px solid var(--blue-primary);
}

._3NIwGsXni65wJmoPS45qFN {

}
