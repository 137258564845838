.WAGUtd8UKAB6KFBQxmdBY {
  /* Don't forget to change the .pushBody class when you change this */
  --header-height: 70px;

  position: fixed;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  width: 100%;
  max-width: var(--view-max-width);
  margin: 0 auto;
  background-color: transparent;
  padding: 5px var(--side-margin-mobile);
  height: var(--header-height);

  /* box-shadow: 0 2px 7px -1px rgba(0, 0, 0, 0.15); */
}

@media (min-width: 768px) {
  .WAGUtd8UKAB6KFBQxmdBY {
    /* box-shadow: none; */
    padding: 5px var(--side-margin-desktop);
    position: absolute;
  }
}

._3xk5zC_Q4RqrA_Y7XEGxf3 {
  width: 100%;
  height: 70px;
}

.PGzuFdQ3e33UOHatixcGk {
  width: 100%;
  height: 100%;
  display: flex;
}

._2uCRcrJjL__kuB5YhncHXt:hover {
  cursor: pointer;
  fill: var(--blue-primary) !important;
}

._2uCRcrJjL__kuB5YhncHXt {
  height: 100%;
  padding: 5px 0;
}

._2uCRcrJjL__kuB5YhncHXt svg {
  height: 100%;
  overflow: visible;
}

._2uCRcrJjL__kuB5YhncHXt svg g g path {
  fill: var(--gray-1) !important;
}

._2uCRcrJjL__kuB5YhncHXt:hover svg g g path {
  fill: var(--blue-primary) !important;
}

.RGP2Y0zsn1s5nbQz8bQDp {
  margin: 0 0;
  font-size: 1.8em;
  margin-inline-start: 7px;
  color: var(--blue-primary);
  color: var(--gray-1);
  font-weight: bold;
}

.hWzXWXAXYjrX9nktAt4Zl {
  margin: 0 0;
  font-size: 0.8em;
  margin-inline-start: 4px;
  color: var(--gray-2);
  font-weight: bold;
}

._1IIlwvFqa-CgQ5Ykp12OT0 {
  padding: 9px 18px;
  border: solid 1px var(--gray-1);
  border-radius: var(--medium-border-radius);
  font-size: 1rem;
}

._1IIlwvFqa-CgQ5Ykp12OT0:hover,
._1IIlwvFqa-CgQ5Ykp12OT0:active {
  border: solid 1px var(--blue-primary);
  background-color: var(--blue-primary);
  color: var(--gray-10) !important;
  transition: background-color 125ms linear;
}

._72nbXdHINzeYjUiDaZ9ok {
  display: flex !important;
  cursor: pointer;
  margin-inline-end: 30px;
  align-items: center;
}

._72nbXdHINzeYjUiDaZ9ok p {
  align-items: center;
  color: var(--gray-3) !important;
}

._72nbXdHINzeYjUiDaZ9ok:hover p {
  color: var(--blue-primary) !important;
}

._72nbXdHINzeYjUiDaZ9ok svg {
  /* Fixes SVG being slightly cutoff in Firefox */
  overflow: visible;
  width: 20px;
  margin-inline-end: 10px;
}

._72nbXdHINzeYjUiDaZ9ok svg g g circle {
  stroke: var(--gray-3);
}

._72nbXdHINzeYjUiDaZ9ok svg g g path {
  stroke: var(--gray-3);
}

._72nbXdHINzeYjUiDaZ9ok:hover svg g g circle {
  stroke: var(--blue-primary);
}

._72nbXdHINzeYjUiDaZ9ok:hover svg g g path {
  stroke: var(--blue-primary);
}

._1xhBw8oGGRi0t3auIFvDhc p {
  color: var(--gray-1) !important;
}

._1xhBw8oGGRi0t3auIFvDhc svg g g circle {
  stroke: var(--gray-1);
}

._1xhBw8oGGRi0t3auIFvDhc svg g g path {
  stroke: var(--gray-1);
}
